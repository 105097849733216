const mixinErrorsParse = {
  methods: {
    mixinErrorsParse(errors) {
      Object.keys(errors).forEach(key => {
        errors[key].forEach(error => {
          this.$validator.errors.add({
            field: key,
            msg: error,
          })
        })
      })
    },
  },
}

export default mixinErrorsParse
